<template>
  <div class="min-h-100 p-0 p-sm-6 d-flex align-items-stretch">
    <router-view />
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>